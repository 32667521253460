export default [
  {
    id: 1,
    name: 'A100',
    locations: [
      // Test
      1, 2, 3,
      // Real
      605, 686, 688, 657, 660, 671, 566, 324, 142,
    ],
  },
];
