import { Tooltip } from '@material-ui/core';
import React from 'react';

export function DownloadButton({ onClick, className }) {
  return (
    <Tooltip placement="top" title="Download the graph">
      <div role="button" className={className} onClick={onClick}>
        <img src="/static/icons/upload.svg" alt="Upload" />
      </div>
    </Tooltip>
  );
}
