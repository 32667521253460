import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Box, makeStyles } from '@material-ui/core';

import { TreeView, TreeItem } from '@material-ui/lab';
import { buildLocationsForAdmin, buildLocationsForUser } from '~/services/api';
import debounce from '~/utils/debounce';
import { authRoles } from '~/constants';
import * as recentlyViewed from '~/store/recentlyViewed';
import { getRecentlyViewed } from '~/services/clientApi';
import { searchLocations } from '~/store/gallery';
import SortBarCompany from '~/components/layouts/DashboardLayout/components/SortBar/SortBarCompany';

const useStyles = makeStyles(theme => ({
  box: {
    width: '320px',
    [theme.breakpoints.up('hiRes')]: {
      width: '17.5vw',
    },
  },
  favorites: {
    fontWeight: 700,
    fontSize: '0.875rem',
    letterSpacing: '0.13em',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
    },
  },
  foldButton: {
    color: 'black',
    position: 'absolute',
    right: -30,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 30,
    outline: 'none!important',
    border: 'none',
    background: 'white',
    padding: 2,
    zIndex: 10,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  searchInput: {
    width: '100%',
    height: '29px',
    color: '#333',
    fontSize: '0.875rem',
    fontWeight: 400,
    padding: '5px 30px 5px 40px',
    outline: 'none',
    backgroundColor: '#F6F5F5',
    backgroundImage: 'url(/static/icons/search.svg)',
    backgroundPosition: '13px 7px',
    backgroundRepeat: 'no-repeat',
    border: '1px solid transparent',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
      height: '2rem',
      padding: '0.3rem 0px 0.3rem 2.6rem',
      backgroundPosition: '0.6rem 0.5rem',
      backgroundSize: '1rem',
    },

    '&:focus': {
      backgroundImage: 'url(/static/icons/search-active.svg)',
      border: '1px solid #36A6FD',
    },
    '&:hover': {
      backgroundImage: 'url(/static/icons/search-active.svg)',
      border: '1px solid #36A6FD',
    },
  },
  searchBox: {
    width: '100%',
    display: 'flex',
  },
  searchClean: {
    width: '10px',
    height: '10px',
    backgroundImage: 'url(/static/icons/cross.svg)',
    margin: '10px 0 0 -22px',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.7em',
      height: '0.7em',
      backgroundSize: 'cover',
      margin: '0.7em 0 0 -1.5em',
    },

    '&:hover': {
      backgroundImage: 'url(/static/icons/cross-hover.svg)',
    },
    '&:focus': {
      outline: 'none',
    },
  },

  recently: {
    fontSize: '12px',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
    },

    '&:hover': {
      background: 'rgba(54, 166, 253, 0.15) !important',
    },
  },

  view: {
    '& .Mui-expanded > .MuiTreeItem-content': {
      background: 'rgba(54, 166, 253, 0.15) !important',
    },
  },

  item: {
    margin: '2px 0',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: '#333',
    fontSize: '0.75rem',
    fontWeight: 400,
    '&:hover, &.active': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
    padding: '2px 4px',
    wordBreak: 'break-all',

    // '&:has( >a.active)': {background: 'rgba(54, 166, 253, 0.15) !important'},

    '& .icon[data-visible="false"]': { display: 'none' },

    '& .icon': {
      width: '10px',
      height: '10px',
      backgroundImage: 'url(/static/icons/open.svg)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      marginLeft: '5px',

      [theme.breakpoints.up('hiRes')]: {
        width: '0.625rem',
        height: '0.625rem',
      },
    },

    '&:hover .icon': {
      backgroundImage: 'url(/static/icons/open-active.svg)',
    },
  },

  hr: {
    width: '100%',
    height: '1px',
    marginBottom: '30px',
    background: 'rgba(229, 229, 229, 0.5)',

    [theme.breakpoints.up('hiRes')]: {
      marginBottom: '0.83rem',
    },
  },
  treeItem: {
    '& .MuiTreeItem-label': { padding: '0 !important' },
    '& .MuiTreeItem-iconContainer': { display: 'none !important' },
    '& .MuiTreeItem-label:hover': {
      background: 'rgba(54, 166, 253, 0.15) !important',
    },

    '&.Mui-expanded > .MuiTreeItem-content .icon': {
      transform: 'rotate(90deg) !important',
    },
    // '& .Mui-selected .MuiTreeItem-label': {background: 'rgba(54, 166, 253, 0.15) !important'},

    // '&[aria-expanded="true"] .icon': {
    //   backgroundImage: 'url(/static/icons/close.svg) !important',
    // },
  },
  expandable: {
    '&.Mui-expanded > .MuiTreeItem-content .icon': {
      transform: 'rotate(90deg) !important',
    },
  },

  treeItemLocation: {
    display: 'block',
    width: '100%',
    // background: 'transparent !important',
    padding: '2px 4px',
    fontSize: '0.75rem',
    fontWeight: 400,

    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&.active': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },

  active: {
    background: 'rgba(54, 166, 253, 0.15)',
  },

  treeItemLocationActiveSearch: {
    color: '#00cf00',
  },

  boxOpen: {
    transition: '1s',
    position: 'relative',
  },

  boxClose: {
    position: 'relative',
    transition: '1s',
    marginLeft: -320,

    [theme.breakpoints.up('hiRes')]: {
      marginLeft: '-17.5vw',
    },
  },

  recViImg: {
    width: '16px',
    height: '16px',
    marginRight: '30px',
    marginLeft: '5px',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.65rem',
      height: '0.65rem',
      marginRight: '2rem',
    },
  },

  companyImg: {
    width: '16px',
    height: '16px',
    marginRight: '10px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1rem',
      height: '1rem',
    },
  },

  favoritesImg: {
    width: '16px',
    height: '16px',
    marginRight: '30px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.1rem',
      height: '1.1rem',
      marginRight: '1.7rem',
    },
  },

  recentlyBox: {
    width: '100%',
    color: '#000',
    margin: '12px 0 6px 0',

    [theme.breakpoints.up('hiRes')]: {
      margin: '0.75rem 0px 0.375rem',
    },

    '& .Mui-expanded > .MuiTreeItem-content': {
      background: 'rgba(54, 166, 253, 0.15) !important',
    },
  },

  favoritesBox: {
    width: '100%',
    color: '#000',
    margin: '12px 0 6px 0',

    [theme.breakpoints.up('hiRes')]: {
      margin: '0.75rem 0px 0.375rem',
    },
  },

  recentlyItem: {
    fontSize: '0.75rem',
    margin: '2px 0 0 50px',

    [theme.breakpoints.up('hiRes')]: {
      margin: '3px 0 0 3.125rem',
    },
  },
}));

function searchClient(text, multilocations, options = {}) {
  const locationSet = options.locationSet || new Set();
  const multilocationSet = options.multilocationSet || new Set();

  let multilocationsCount = 0;

  for (const { locations, multilocationName } of multilocations) {
    if (multilocationName.toLowerCase().includes(text)) {
      multilocationSet.add(multilocationName);
      multilocationsCount++;
    }

    for (const l of locations) {
      if (l.name.toLowerCase().includes(text)) {
        locationSet.add(l.name);
        multilocationSet.add(multilocationName);
        multilocationsCount++;
      }
    }
  }

  return {
    locations: locationSet,
    multilocations: multilocationSet,
    multilocationsCount,
  };
}

function searchAdmin(text, companies) {
  const companySet = new Set();
  const locationSet = new Set();
  const multilocationSet = new Set();

  for (const { companyName, multilocations } of companies) {
    const searchResult = searchClient(text, multilocations, {
      locationSet,
      multilocationSet,
    });

    if (
      companyName.toLowerCase().includes(text) ||
      searchResult.multilocationsCount > 0
    ) {
      companySet.add(companyName);
    }
  }

  return {
    companies: companySet,
    locations: locationSet,
    multilocations: multilocationSet,
  };
}

function useLoadRecentlyViewed() {
  const dispatch = useDispatch();
  const loaded = useSelector(state => state.recentlyViewed.loaded);
  const items = useSelector(state => state.recentlyViewed.value);

  useEffect(() => {
    if (!loaded) {
      getRecentlyViewed()
        .then(result => dispatch(recentlyViewed.load(result)))
        .catch(console.error);
    }
  }, [dispatch, loaded]);

  return items;
}

const SortBar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [normalSearchText, setNormalSearchText] = useState('');
  const searchText = useSelector(state => state.gallery.search);
  const [panelVisible, setPanelVisible] = useState(true);
  const favorites = useSelector(state => state.auth.favorites);
  const user = useSelector(state => state.auth.user);

  const isAdmin = user.role === authRoles.ADMIN;

  const locations = useAsync(
    () => buildLocationsForUser(user.company).catch(() => []),
    [user.company],
  );
  const allLocationsForAdmin = useAsync(
    () => buildLocationsForAdmin().catch(() => []),
    [],
  );

  const recentlyViewedItems = useLoadRecentlyViewed();

  if (locations.loading || allLocationsForAdmin.loading) {
    return null;
  }

  let nodeId = 0;

  const updateSearchText = debounce(value => {
    dispatch(searchLocations(value.toLowerCase().trim()));
  }, 100);

  const handleChangeSearch = ({ target: { value } }) => {
    updateSearchText(value);
    setNormalSearchText(value);
  };

  const handleClearSearch = () => {
    dispatch(searchLocations(''));
    setNormalSearchText('');
  };

  const searchResult = isAdmin
    ? searchAdmin(searchText, allLocationsForAdmin.value)
    : searchClient(searchText, locations.value);

  return (
    <div className={panelVisible ? classes.boxOpen : classes.boxClose}>
      <button
        type="button"
        className={classes.foldButton}
        onClick={() => setPanelVisible(!panelVisible)}
      >
        <img
          src={`/static/icons/${
            panelVisible ? 'menu_fold_icon.png' : 'menu_unfold_icon.png'
          }`}
          alt="Menu Fold"
        />
      </button>

      <Box
        style={{ overflowY: 'auto' }}
        flexShrink="0"
        padding="8.7% 7%"
        height="100%"
        display="flex"
        flexDirection="column"
        bgcolor="#fff"
        alignItems="center"
        color="#fff"
        className={classes.box}
      >
        <div className={classes.searchBox}>
          <input
            name="text"
            placeholder="Search"
            className={classes.searchInput}
            // onChange={e => updateSearchText(e.target.value)}
            onChange={handleChangeSearch}
            value={normalSearchText}
          />
          <button
            className={classes.searchClean}
            onClick={handleClearSearch}
            type="button"
          />
        </div>
        <TreeView className={classes.recentlyBox}>
          <TreeItem
            nodeId="Recently"
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '2px 0',
                }}
                className={classes.recently}
              >
                <img
                  alt=""
                  className={classes.recViImg}
                  src="/static/icons/recently.svg"
                />
                Recently Viewed
              </div>
            }
            className={classes.treeItem}
            style={{
              backgroundColor: 'transparent',
            }}
          >
            {recentlyViewedItems.map((item, index) => {
              if (index < 5) {
                const tour = isAdmin
                  ? (
                      allLocationsForAdmin.value.find(c =>
                        c.multilocations.some(
                          m => m.multilocationName === item.listId,
                        ),
                      ) || { multilocations: [] }
                    ).multilocations.find(
                      m => m.multilocationName === item.listId,
                    )
                  : locations.value.find(
                      v => v.multilocationName === item.listId,
                    );

                if (!tour) return null;

                const mainLocation =
                  tour.locations.find(l => l.description.IS_MAIN) ||
                  tour.locations[0];

                return (
                  <TreeItem
                    key={item.id}
                    style={{ color: '#000' }}
                    label={
                      <NavLink
                        to={`/overview/multi/${encodeURIComponent(
                          tour.companyName,
                        )}/${encodeURIComponent(
                          mainLocation.description.LIST_ID,
                        )}`}
                      >
                        <div className={classes.recentlyItem}>
                          {item.listId}
                        </div>
                      </NavLink>
                    }
                    nodeId={`${index}Recently`}
                  />
                );
              }
            })}
          </TreeItem>
        </TreeView>
        <div className={classes.hr} style={{ marginBottom: '0px' }} />
        <TreeView className={classes.favoritesBox}>
          <TreeItem
            className={classes.treeItem}
            style={{
              background: 'transparent',
            }}
            nodeId="1"
            label={
              <div
                className={cn({
                  [classes.item]: true,
                  [classes.active]:
                    window.location.pathname.includes('favorites'),
                })}
              >
                <div style={{ display: 'flex' }}>
                  <img
                    alt=""
                    className={classes.favoritesImg}
                    src="/static/icons/favorites.svg"
                  />
                  <NavLink
                    to="/gallery/favorites"
                    className={classes.favorites}
                  >
                    FAVORITES
                  </NavLink>
                </div>
                <div className="icon" data-visible />
              </div>
            }
          >
            {Object.keys(favorites).map(listId => {
              const tour = isAdmin
                ? (
                    allLocationsForAdmin.value.find(c =>
                      c.multilocations.some(
                        m => m.multilocationName === listId,
                      ),
                    ) || { multilocations: [] }
                  ).multilocations.find(m => m.multilocationName === listId)
                : locations.value.find(v => v.multilocationName === listId);

              if (!tour) return null;

              const mainLocation =
                tour.locations.find(l => l.description.IS_MAIN) ||
                tour.locations[0];

              return (
                <TreeItem
                  style={{ paddingRight: 4 }}
                  key={`favorite-${listId}`}
                  nodeId={`favorite-${listId}`}
                  className={classes.expandable}
                  label={
                    <div className={classes.item}>
                      <NavLink
                        to={`/overview/multi/${encodeURIComponent(
                          tour.companyName,
                        )}/${encodeURIComponent(
                          mainLocation.description.LIST_ID,
                        )}`}
                        className={cn({
                          [classes.treeItemLocation]: true,
                        })}
                      >
                        {listId}
                      </NavLink>
                    </div>
                  }
                />
              );
            })}
          </TreeItem>
        </TreeView>
        <div className={classes.hr} />
        {isAdmin &&
          allLocationsForAdmin.value
            .filter(({ companyName }) =>
              searchResult.companies.has(companyName),
            )
            .map(({ companyName, multilocations, locations }, index) => {
              if (!multilocations.length && locations.length) {
                return (
                  <React.Fragment key={companyName + index.toString()}>
                    <TreeView
                      style={{
                        lineHeight: 1,
                        paddingTop: '5px',
                        paddingLeft: '60px',
                        paddingRight: 4,
                        width: '100%',
                        color: '#000',
                      }}
                    >
                      <NavLink to={`/overview/location/${locations[0].id}`}>
                        <SortBarCompany
                          companyName={companyName}
                          classes={classes}
                          multilocations={multilocations}
                          searchResult={searchResult}
                          index={index}
                          searchText={searchText}
                        />
                      </NavLink>
                    </TreeView>
                    <div className={classes.hr} style={{ margin: '4px 0' }} />
                  </React.Fragment>
                );
              }

              return (
                <React.Fragment key={companyName + index.toString()}>
                  <TreeView
                    style={{
                      lineHeight: 1,
                      paddingTop: '5px',
                      paddingLeft: '60px',
                      paddingRight: 4,
                      width: '100%',
                      color: '#000',
                    }}
                  >
                    <SortBarCompany
                      companyName={companyName}
                      classes={classes}
                      multilocations={multilocations}
                      searchResult={searchResult}
                      index={index}
                      searchText={searchText}
                    />
                  </TreeView>
                  <div className={classes.hr} style={{ margin: '4px 0' }} />
                </React.Fragment>
              );
            })}
        {!isAdmin &&
          locations.value
            .filter(({ multilocationName }) =>
              searchResult.multilocations.has(multilocationName),
            )
            .map(({ multilocationName, companyName, locations, id }) => {
              return (
                <TreeView
                  key={multilocationName + id}
                  style={{
                    lineHeight: '1',
                    paddingTop: '5px',
                    paddingLeft: '60px',
                    width: '100%',
                    color: '#000',
                  }}
                >
                  <TreeItem
                    className={classes.treeItem}
                    nodeId={String(++nodeId)}
                    label={
                      <div className={classes.item}>
                        <div style={{ display: 'flex' }}>
                          <img
                            alt=""
                            style={{
                              width: '1rem',
                              height: '1rem',
                              marginRight: '0.625rem',
                            }}
                            src="/static/icons/folder.svg"
                          />

                          {multilocationName &&
                          multilocationName !== locations[0].name ? (
                            <NavLink
                              to={`/overview/multi/${encodeURIComponent(
                                companyName,
                              )}/${encodeURIComponent(multilocationName)}`}
                              style={{
                                fontWeight: 700,
                                fontSize: '0.875rem',
                                letterSpacing: '0.13em',
                                textTransform: 'uppercase',
                                maxWidth: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              className="valodik"
                            >
                              {multilocationName}
                            </NavLink>
                          ) : (
                            <NavLink
                              className="valodik"
                              to={`/overview/location/${id}`}
                              style={{
                                fontWeight: 700,
                                fontSize: '0.875rem',
                                letterSpacing: '0.13em',
                                textTransform: 'uppercase',
                                maxWidth: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {locations[0].name}
                            </NavLink>
                          )}
                        </div>

                        <div
                          className="icon"
                          data-visible={Boolean(multilocationName)}
                        />
                      </div>
                    }
                  >
                    {locations.length > 0 &&
                      locations.map(location => (
                        <TreeItem
                          className={classes.treeItem}
                          key={`${location.name} ${location.id}`}
                          nodeId={String(++nodeId)}
                          label={
                            <div className={classes.item}>
                              <NavLink
                                to={`/overview/location/${location.id}`}
                                className={cn({
                                  [classes.treeItemLocation]: true,
                                  [classes.treeItemLocationActiveSearch]:
                                    !!searchText &&
                                    searchResult.locations.has(location.name),
                                })}
                              >
                                {location.name}
                              </NavLink>
                            </div>
                          }
                        />
                      ))}
                  </TreeItem>
                </TreeView>
              );
            })}
      </Box>
    </div>
  );
};

export default SortBar;
