const CDN_URL = process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT || '';
const STAGING_CDN_URL = process.env.SNOWPACK_PUBLIC_STAGING_CDN_ENDPOINT || '';

export function getStagingPointPreviewURL(point, location) {
  return `${STAGING_CDN_URL}/locations/${location}/model/cube/${point}_cubefront_0-0-0.jpg`;
}

export default function getPointPreviewURL(point, location) {
  return `${CDN_URL}/locations/${location}/model/cube/${point}_cubefront_0-0-0.jpg`;
}

export function getPointCubePreviewUrl(point, location) {
  return `${CDN_URL}/locations/${location}/model/cube/${point}_cubefront.jpg`;
}
