import { combineReducers } from 'redux';
import authReducer from './auth';
import recentlyViewedReducer from './recentlyViewed';
import galleryReducer from './gallery';
import allMultitourStatsReducer from './allMultitourStats';
import datePickerReducer from './datePicker';
import tooltipDurationReducers from './durationTooltip';
import visitsTooltip from './visitsTooltip';
import pointsVisitsTooltip from './pointsVisitsTooltip';
import timeStep from './timeStep';

export default combineReducers({
  auth: authReducer,
  recentlyViewed: recentlyViewedReducer,
  gallery: galleryReducer,
  allMultitourStats: allMultitourStatsReducer,
  datePicker: datePickerReducer,
  durationTooltip: tooltipDurationReducers,
  visitsTooltip,
  pointsVisitsTooltip,
  timeStep,
});
