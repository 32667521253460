/* eslint-disable prettier/prettier */
import moment from 'moment';

export function filterStatsByDate(data, date) {
  if(data == null){
    return []
  }

  if (typeof data[Symbol.iterator] !== 'function') {
    return [];
  }

  const filterFunc = event => {
    const eventDate = event.eventDate ? event.eventDate : event.data.eventDate;
    const momentEventDate = moment(eventDate);
    return (
      momentEventDate.isSameOrAfter(date[0]) &&
      momentEventDate.isSameOrBefore(date[1])
    );
  };

  const sortFunc = (a, b) => {
    const dateA = a.eventDate ? a.eventDate : a.data.eventDate;
    const dateB = b.eventDate ? b.eventDate : b.data.eventDate;
    return moment(dateB).diff(moment(dateA));
  };

  const filteredData = date[0] ? [...data].filter(filterFunc) : [...data];

  return filteredData.sort(sortFunc);
}

export const formatLabels = (data, name) => {
  switch (name) {
    case 'day':
    case 'week': {
      return data.map(item => moment(item.eventDate).format('DD.MM'));
    }
    case 'month': {
      return data
        .map(item => moment(item.eventDate).format('MMMM YY'))
    }
    default: {
      break;
    }
  }
};

export const calculateChartSize = name => {
  switch (name) {
    case 'day':
      return 16;
    case 'week':
      return 24;
    case 'month':
      return 32;
    default:
      break;
  }
};

// eventDate, value
export function calculateEventsInIntervals({data, range, start, end}){
  if(data == null){
    return []
  }

  if (typeof data[Symbol.iterator] !== 'function') {
    return [];
  }

  let step = 1

  switch (range) {
    case 'day':
      step = 1      
      break;
    case 'week':
      step = 7
      break;
    case 'month':
      step = 30
      break;
    default:
      break;
  }
  
  const format = 'YYYY-MM-DD'
  const intervals = []

  const from = moment(start, format)
  const to = moment(end, format)
  const current = from.clone()

  data.sort((a, b) => {
    return a.eventDate > b.eventDate ? 1 : -1
  })
  
  while(current.isSameOrBefore(to)){
    const intervalEnd = current.clone().add(step, 'days')
    intervals.push({
      start: current.clone(),
      end: intervalEnd.isSameOrBefore(to) ? intervalEnd : to.clone(),
      sum: 0,
      count: 0,
    })
    
   current.add(step, 'days');
  }

  let currentIntervalIdx = 0

  for(let i = 0; i < data.length; i++){
    const date = moment(data[i].eventDate, format)
    let currentInterval = intervals[currentIntervalIdx]

    if(date.isBefore(currentInterval.start)){
      // eslint-disable-next-line no-continue
      continue
    }

    while(currentInterval && !(date.isSameOrAfter(currentInterval.start) && (currentIntervalIdx === intervals.length - 1 ? date.isSameOrBefore(currentInterval.end) : date.isBefore(currentInterval.end)))){
      currentIntervalIdx ++;
      currentInterval = intervals[currentIntervalIdx]
    }

    if(currentInterval){
      currentInterval.sum += data[i].value;
      currentInterval.count ++;
    } else {
      break
    }
  }

  return intervals.map(({start, sum}) => ({
    eventDate: start.format(format),
    value: sum
  })) 
}


export function calculateImgSize(img, w, h, maxWidth=700, maxHeight=410){
  if(w === 0 || h === 0){
    return {
      image: img,
      width: w,
      height: h,
    }
  }

  const ratio = (h / w)

  if (ratio === 1){
    return {
      image: img,
      width: w,
      height: h,
    }
  }

  let newH = maxHeight
  let newW = h / ratio

  if (newW > maxWidth){
    newW = maxWidth
    newH = maxWidth * ratio
  }

  if(newH > maxHeight){
    return calculateImgSize(img, newW, newH, maxWidth, maxHeight)
  }

  return {
    image: img,
    width: newW,
    height: newH,
  }
}


export function renderDecimal(num){
  if(num < 1 && num > -1){
    return num.toFixed(2)
  }

  return Math.round(num).toString()
}
