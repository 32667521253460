import { createSlice } from '@reduxjs/toolkit';
import { TOOLTIP_INITIAL } from '~/views/private/overview/constants';

const pointsVisitsTooltip = createSlice({
  name: 'pointsVisitsTooltip',
  initialState: TOOLTIP_INITIAL,
  reducers: {
    setPointsVisitTooltipInfo: (state, action) => {
      return action.payload;
    },
    updatePointsVisitTooltipInfo: (state, action) => {
      return { ...state, ...action.payload };
    },
    hidePointVisitsTooltip: (state, action) => {
      if (action.payload && action.payload.force) {
        return {
          show: false,
          showDashes: false,
          showLabel: false,
          showCircle: false,
        };
      }

      if (state.cursorInside) {
        return state;
      }

      if (!state.show) {
        return state;
      }

      return {
        show: false,
        showDashes: false,
        showLabel: false,
        showCircle: false,
      };
    },
  },
});

export const {
  setPointsVisitTooltipInfo,
  updatePointsVisitTooltipInfo,
  hidePointVisitsTooltip,
} = pointsVisitsTooltip.actions;

export default pointsVisitsTooltip.reducer;
