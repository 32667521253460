import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';

import {
  buildLocationsForAdmin,
  buildLocationsForUser,
  getMultiLocationStats,
  getSummaryStats,
} from '~/services/api';
import { authRoles } from '~/constants';
import TourCard from '../components/TourCard';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },
  backIcon: {
    display: 'block',
    width: '18px',
    height: '17px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
}));

const fetchTourStats = tour => {
  const mainLocation =
    tour.locations.find(l => l.description.IS_MAIN) || tour.locations[0];
  if (mainLocation.name !== tour.multilocationName) {
    return getMultiLocationStats(tour.multilocationName, tour.companyName);
  }
  return getSummaryStats(mainLocation.id);
};

const FavoriteGalleryView = () => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const isAdmin = user.role === authRoles.ADMIN;

  const favorites = useSelector(state => state.auth.favorites);

  const locations = useAsync(async () => {
    const tourList =
      (await (isAdmin
        ? buildLocationsForAdmin().then(companies =>
            companies.flatMap(c => c.multilocations),
          )
        : buildLocationsForUser(user.company))) || [];
    return Promise.all(
      tourList
        .filter(t => t.locations.length > 0)
        .map(async tour => {
          const stats = (await fetchTourStats(tour)) || {};
          return { ...tour, stats };
        }),
    );
  }, [isAdmin]);

  if (!locations.value) {
    return null;
  }

  return (
    <Page className={classes.root} title="Favorites">
      <Header>
        <div style={{ fontSize: '1.5rem' }}>Favorites</div>
        <NavLink to="/gallery" className={classes.backIcon} />
      </Header>

      <Grid
        container
        spacing={4}
        style={{ paddingTop: '30px', paddingRight: '40px' }}
      >
        {Object.keys(favorites).map(listId => {
          const tour = locations.value.find(
            v => v.multilocationName === listId,
          );

          if (!tour) return null;

          return (
            <Grid key={tour.id} item lg={4} md={6} xs={12}>
              <TourCard tour={tour} />
            </Grid>
          );
        })}
      </Grid>
    </Page>
  );
};

export default FavoriteGalleryView;
