import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    color: '#333',

    [theme.breakpoints.up('hiRes')]: {
      paddingLeft: '1.6rem',
    },
  },

  contactItem: {
    fontSize: '1.5rem',
    fontWeight: 600,
    marginBottom: '40px',

    [theme.breakpoints.up('hiRes')]: {
      marginBottom: '2.6rem',
    },
  },
  contactItemNumber: {
    marginTop: '10px',
    color: '#A0A0A0',
  },
  contactsContainer: {
    paddingTop: '70px',

    [theme.breakpoints.up('hiRes')]: {
      paddingTop: '6.2rem',
    },
  },
  header: {
    fontSize: '1.5rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1.33rem',
    },
  },
  header2: {
    fontSize: '0.875rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
    },
  },
}));

const ContactsView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Contacts">
      <Header>
        <div className={classes.header}>Contact Us</div>
        <div className={classes.header2}>
          Please reach out with any questions
        </div>
      </Header>

      <div className={classes.contactsContainer}>
        <div className={classes.contactItem}>
          <div style={{ textTransform: 'uppercase' }}>Headquarters:</div>
          <div className={classes.contactItemNumber}>
            501 Congress Avenue, Suite 150
            <br />
            Austin, TX 78701
            <br />
            Working days: Monday - Friday
            <br />
            Working hours: 9 AM - 6 PM
          </div>
        </div>

        <div className={classes.contactItem}>
          <div style={{ textTransform: 'uppercase' }}>Phone:</div>
          <div className={classes.contactItemNumber}>512-931-1766</div>
        </div>
        <div className={classes.contactItem}>
          <div style={{ textTransform: 'uppercase' }}>E-mail</div>
          <div className={classes.contactItemNumber}>info@theviewpro.com</div>
        </div>
      </div>
    </Page>
  );
};

export default ContactsView;
