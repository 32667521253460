import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRange, setUseCustomRange } from '~/store/datePicker';
import { Button } from '@material-ui/core';
import CustomDateRangePicker from './DateRangePicker';

const MultiDateRangePicker = ({}) => {
  const dispatch = useDispatch();

  const range = useSelector(state => state.datePicker.range);
  const initialRange = useSelector(state => state.datePicker.initialRange);

  const handleDateChange = newDate => {
    dispatch(setRange(newDate));
  };

  const resetHandler = () => {
    dispatch(setRange(initialRange));
    dispatch(setUseCustomRange(false));
  };

  const openHandler = () => {
    dispatch(setUseCustomRange(true));
  };

  return (
    <div className="flex gap-x-4">
      <CustomDateRangePicker
        value={range}
        onChange={handleDateChange}
        onOpen={openHandler}
      />
      <Button
        variant="outlined"
        onClick={resetHandler}
        size="small"
        style={{ height: '1.13rem' }}
      >
        Reset
      </Button>
    </div>
  );
};

export default MultiDateRangePicker;
