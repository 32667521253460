import React from 'react';
import { useAsync } from 'react-use';
import { Bar } from 'react-chartjs-2';
import {
  Container,
  Box,
  Grid,
  colors,
  makeStyles,
  Typography,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from '@material-ui/core';
import { getStats, getSummaryStats } from '~/services/api';
import getPointPreviewURL from '~/utils/getPointPreviewURL';
import timeStringFromSeconds from '~/utils/timeFromSeconds';
import { eventTypes, menuItems } from './constants';
import Chart from './components/Chart';

// import { visitStats, pointStats, menuStats, multitourStats } from './data';

// Uncomment to use mock data
// import { menuItems } from './constants';

// Uncomment to use mock data
// function Analytics() {

const useStyles = makeStyles(() => ({
  root: {},
  image: {
    display: 'block',
    height: 80,
    width: 80,
    marginTop: 5,
    backgroundColor: '#999999',
  },
}));

function Analytics({ location }) {
  const classes = useStyles();
  const pointDurationStats = useAsync(
    () => getStats(location.id, eventTypes.POINT_DURATION),
    [],
  );
  const pointStats = useAsync(
    () => getStats(location.id, eventTypes.POINT),
    [],
  );
  const menuStats = useAsync(() => getStats(location.id, eventTypes.MENU), []);
  const visitStats = useAsync(
    () => getStats(location.id, eventTypes.VISIT),
    [],
  );
  const multitourStats = useAsync(
    () =>
      getStats(location.id, eventTypes.MULTITOUR, location.description.LIST_ID),
    [],
  );

  const summaryStats = useAsync(() => getSummaryStats(location.id), []);
  console.log('pointDurationStats', pointDurationStats);
  console.log('summaryStats', summaryStats);

  return (
    <Container maxWidth={false}>
      <Box>
        <Grid container spacing={3}>
          {!pointDurationStats.loading && pointDurationStats.value && (
            <Grid item lg={6} md={6} xs={12}>
              <Chart
                as={Bar}
                title="Point View Duration"
                datasets={[
                  {
                    backgroundColor: colors.blue[500],
                    data: pointDurationStats.value.map(item => item.duration),
                    label: ' duration',
                  },
                ]}
                labels={pointDurationStats.value.map(
                  item => `Point #${item.pointId}`,
                )}
              />
            </Grid>
          )}
          {!visitStats.loading && visitStats.value && (
            <Grid item lg={6} md={6} xs={12}>
              <Chart
                as={Bar}
                title="Visit"
                datasets={[
                  {
                    backgroundColor: colors.blue[500],
                    data: visitStats.value.map(item => item.eventsCount),
                    label: ' visits',
                  },
                ]}
                labels={visitStats.value.map(item => item.eventDate)}
              />
            </Grid>
          )}
          {!pointStats.loading && pointStats.value && (
            <Grid item lg={6} md={6} xs={12}>
              <Chart
                as={Bar}
                title="Point"
                datasets={[
                  {
                    backgroundColor: colors.blue[500],
                    data: pointStats.value
                      .map(item => item.eventsCount)
                      .sort((a, b) => b - a),
                    label: ' clicks',
                  },
                ]}
                customTooltip={data => {
                  const pointId =
                    parseInt(data.title.replace('Point #', ''), 10) + 1;
                  return (
                    <div>
                      <Typography variant="h5">{data.title}</Typography>
                      <Typography variant="body1">{data.body}</Typography>
                      {!Number.isNaN(pointId) && (
                        <img
                          className={classes.image}
                          src={getPointPreviewURL(pointId, location.id)}
                        />
                      )}
                    </div>
                  );
                }}
                alt="any"
                alt="any"
                labels={pointStats.value.map(item => `Point #${item.pointId}`)}
              />
            </Grid>
          )}
          {!menuStats.loading && menuStats.value && (
            <Grid item lg={6} md={6} xs={12}>
              <Chart
                as={Bar}
                title="Menu"
                datasets={[
                  {
                    backgroundColor: colors.blue[500],
                    data: menuStats.value.map(item => item.eventsCount),
                    label: ' clicks',
                  },
                ]}
                labels={menuStats.value.map(item => menuItems[item.itemId])}
              />
            </Grid>
          )}
          {!multitourStats.loading && multitourStats.value && (
            <Grid item lg={6} md={6} xs={12}>
              <Chart
                as={Bar}
                title="Multitour"
                datasets={[
                  {
                    backgroundColor: colors.blue[500],
                    data: multitourStats.value.map(item => item.eventsCount),
                    label: ' clicks',
                  },
                ]}
                labels={multitourStats.value
                  .filter(item => item.name !== 'null')
                  .map(item => item.name)}
              />
            </Grid>
          )}
        </Grid>
        {!summaryStats.loading && summaryStats.value && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title={'Summary'} />
                <Divider />
                <CardContent>
                  <Typography variant="body1">
                    {' '}
                    Total visits: {summaryStats.value.totalVisits}{' '}
                  </Typography>
                  <Typography variant="body1">
                    {' '}
                    Top points:{' '}
                    {summaryStats.value.topPoints
                      .map(p => p.pointId)
                      .join(', ')}{' '}
                  </Typography>
                  <Typography variant="body1">
                    {' '}
                    Total duration:{' '}
                    {timeStringFromSeconds(
                      summaryStats.value.totalDuration,
                    )}{' '}
                  </Typography>
                  <Typography variant="body1">
                    {' '}
                    Average visit duration:{' '}
                    {timeStringFromSeconds(
                      summaryStats.value.avgVisitDuration,
                    )}{' '}
                  </Typography>
                  <Typography variant="body1">
                    {' '}
                    Average visits per day: {
                      summaryStats.value.avgVisitsPerDay
                    }{' '}
                  </Typography>
                  <Typography variant="body1">
                    {' '}
                    Average visits per month:{' '}
                    {summaryStats.value.avgVisitsPerMonth}{' '}
                  </Typography>
                  <Typography variant="body1">
                    {' '}
                    Total custom button clicks:{' '}
                    {summaryStats.value.totalCustomButtonClicks}{' '}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
}

export default Analytics;
