/* eslint-disable prettier/prettier */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { makeStyles, Button, Tooltip } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useAsync } from 'react-use';
import moment from 'moment';
import {
  setVisitsTooltipInfo,
  updateVisitsTooltipInfo,
} from '~/store/visitsTooltip';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';
import { eventTypes } from '~/views/private/location/LocationView/components/Analytics/constants';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import {
  getStats,
  getMultiLocationStats,
  getLocationsViaList,
  getMultitourPointViewDurationStats,
  getMultitourViewDurationStats,
  getMultitourPointClicksStats,
  getMultitourVisitsStats,
  getMultitourMenuClickStats,
  getLocation,
} from '~/services/api';
import findMax from '~/utils/findMax';
import { formatDateFromSeconds } from '~/utils/helper';
import {
  RecentlyViewedType,
  saveRecentlyViewed,
  FavoriteType,
  saveFavorite,
  removeFavoriteTour,
} from '~/services/clientApi';
import * as recentlyViewed from '~/store/recentlyViewed';
import * as auth from '~/store/auth';
import { getShortNumber } from '~/utils/getShortNumber';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';
import TopPoint from '~/views/private/overview/Components/TopPoint';
import getTooltipValue from '~/utils/getTooltipValue';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { changeVisible, setInitialRange, setRange, trySetRange } from '~/store/datePicker';
import {
  updateDurationTooltipInfo,
  setDurationTooltipInfo,
  hideDurationTooltip,
} from '~/store/durationTooltip';
import {
  updatePointsVisitTooltipInfo,
  setPointsVisitTooltipInfo,
  hidePointVisitsTooltip,
} from '~/store/pointsVisitsTooltip';
import { TOOLTIP_TYPE, GRAPH_TYPE, RANGE_TYPE } from './constants';
import { GraphTooltip } from './Components/GraphTooltip';
import MultiDateRangePicker from './Components/MultiDateRangePicker';
import CopyInput from './Components/CopyInput';
import ViewsByDevices from './Components/ViewsByDevices';
import {
  calculateChartSize,
  calculateEventsInIntervals,
  calculateImgSize,
  filterStatsByDate,
  formatLabels,
  renderDecimal,
} from './utils';
import TimeStep from './Components/TimeStep';
import { DownloadButton } from './Components/DownloadButton';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '2.8em',
    paddingBottom: '0px',
  },
  backIcon: {
    marginRight: '14px',
    display: 'block',
    width: '25px',
    height: '24px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
  infoCard: {
    cursor: 'pointer',
    '&:hover>div': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
  viewDurationSettersWrapper: {
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 1,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    borderRadius: 6,
  },
  viewDurationSetter: {
    color: '#CBC9C9',
    cursor: 'pointer',
    fontSize: '0.75rem',
    padding: '3px 10px',
    borderRadius: 6,
    background: 'rgba(54, 166, 253, 0.05)',
    '&.active': {
      background: 'rgba(54, 166, 253, 0.15)',
      color: '#333',
    },
  },
  upload: {
    background: 'rgba(54, 166, 253, 0.15)',
    borderRadius: 6,
    padding: '3px 4px',
    width: '24px',
    display: 'flex',
    marginLeft: 6,
    height: '24px',
    cursor: 'pointer',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.4rem',
      height: '1.4rem',
      padding: '0.28rem',
    },
  },
  h12: {
    height: '370px',

    [theme.breakpoints.up('hiRes')]: {
      height: '12vw',
    },
  },
  h14: {
    height: '370px',

    [theme.breakpoints.up('hiRes')]: {
      height: '14.5vw',
    },
  },
  doughnutContainer: {
    '& > canvas': {
      margin: '0 auto',
      maxWidth: '541px',

      [theme.breakpoints.up('hiRes')]: {
        maxWidth: '25vw',
      },
    },
  },
  triangle: {
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '5px 0 5px 5px',
    borderColor: 'transparent transparent transparent #000000',

    [theme.breakpoints.up('hiRes')]: {
      borderWidth: '0.3rem 0 0.3rem 0.3rem',
    },
  },
  activeNumber: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    bottom: '4px',
    pointerEvents: 'none',
    height: '12px',
    color: '#36A6FD',
    fontSize: '0.625rem',

    [theme.breakpoints.up('hiRes')]: {
      bottom: '0.25rem',
      height: '0.75rem',
    },
  },
  smallTooltip: {
    borderRadius: '3px',
    transform: 'translate(-50%, -50%)',
    width: '50px',
    height: '30px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '3.125rem',
      height: '1.875rem',
    },
  },
  bigTooltip: {
    transform: 'translate(-50%, -100%)',
    borderRadius: '3px',
    width: '187px',
    paddingBottom: '20px',
    transition: 'opacity, left 0.1s ease',
    display: 'block',
    zIndex: 10,

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '11.69rem',
      height: '10rem',
      paddingBottom: '1.25rem',
    },
  },
  smallTriangle: {
    position: 'absolute',
    top: '20px',
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      top: '1.25rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  verySmallTriangle: {
    position: 'absolute',
    top: '-6px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #363636',
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',

    [theme.breakpoints.up('hiRes')]: {
      top: '-0.375rem',
      borderLeft: '0.625rem solid transparent',
      borderRight: '0.625rem solid transparent',
      borderBottom: '0.625rem solid #363636',
    },
  },
  bigTooltipPaper: {
    padding: '5px 5px 15px',
    pointerEvents: 'none',
    background: '#36A6FD',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.3rem 0.3rem 1rem',
    },
  },
  bigTriangle: {
    position: 'absolute',
    bottom: '5px',
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',

    [theme.breakpoints.up('hiRes')]: {
      top: '8.4375rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  tooltipImg: {
    height: '70px',
    width: '100%',
    objectFit: 'cover',
    position: 'relative',

    [theme.breakpoints.up('hiRes')]: {
      height: '4.375rem',
    },
  },
  bigCircle: {
    width: '20px',
    height: '20px',
    background: 'rgb(54, 166, 253, 0.2)',
    top: '40px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.25rem',
      height: '1.25rem',
      top: '2.5rem',
    },
  },
  mediumCircle: {
    width: '14px',
    height: '14px',
    background: 'rgb(54, 166, 253, 0.4)',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.875rem',
      height: '0.875rem',
    },
  },
  smallCircle: {
    width: '7px',
    height: '7px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.5rem',
      height: '0.5rem',
    },
  },
  topPointImg: {
    maxWidth: '200px',

    [theme.breakpoints.up('hiRes')]: {
      maxWidth: '12.5rem',
    },
  },
  value: {
    fontSize: '1.25rem',
    lineHeight: '2rem',

    [theme.breakpoints.up('lowRes')]: {
      fontSize: '1.5rem',
    },
  },
  tooltip: {
    tooltip: {
      margin: 10,
    },
  },
}));

const charts = {
  VISITS: 'Visits',
  VIEW_DURATION: 'View Duration',
  POINTS_VISITS: 'Point Visits',
};

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    margin: 10,
  },
}));

function InfoCard({ className = '', title, value, fullTitle, tooltipText }) {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();

  return (
    <Tooltip
      classes={tooltipClass}
      placement="top"
      title={tooltipText || `${fullTitle || title}: ${getTooltipValue(value)}`}
    >
      <div className={clsx('flex-auto mb-3', classes.infoCard, className)}>
        <div
          className="w-full bg-white flex flex-col justify-center rounded-lg"
          style={{
            padding: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          {title}
          <span className={`font-bold ${classes.value}`}>
            {getShortNumber(value)}
          </span>
        </div>
      </div>
    </Tooltip>
  );
}

const getPointDuration = pointStat => (pointStat ? pointStat.duration : 0);

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(54,166,253,0.6)');
gradient.addColorStop(1, 'rgba(54,166,253,0)');

const viewDurationGradient = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradient.addColorStop(0, 'rgba(54,166,253,0.33)');
viewDurationGradient.addColorStop(1, 'rgba(54,166,253,0.165)');

const viewDurationGradientHover = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradientHover.addColorStop(0, 'rgba(54,166,253,0.8)');
viewDurationGradientHover.addColorStop(1, 'rgba(54,166,253,0.4)');

const hideTimeout = 0;

const MultilocationOverviewView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const favorites = useSelector(state => state.auth.favorites);
  const visitsDateValue = useSelector(state => state.datePicker.range)
  const currentStep = useSelector(state => state.timeStep.value).find(ts => ts.isSelected).name
  
  const refVisits = useRef();
  const refDuration = useRef();
  const refPointVisits = useRef();
  const refDoughnut = useRef();

  const refVisitsScroll = useRef();
  const refDurationScroll = useRef();

  const timeout = useRef({
    show: false,
    timeout: undefined,
  });

  let { listId, companyName } = useParams();
  listId = decodeURIComponent(listId);
  companyName = decodeURIComponent(companyName);

  const [isTitles, setIsTitles] = useState({});

  const [currentViewDurationEntity, setCurrentViewDurationEntity] = useState(
    GRAPH_TYPE.LOCATION,
  );

  const [viewDurationStats, setViewDurationStats] = useState(null);

  const [filteredVisitStats, setFilteredVisitStats] = useState([]);
  const [filteredViewDurationStats, setFilteredViewDurationStats] = useState(
    [],
  );

  const [isPdfDownloaded, setIsPdfDownloaded] = useState(true);

  const [isChartLoading, setIsChartLoading] = useState({
    visitStats: false,
    viewDurationStats: false,
  });

  const summaryStats = useAsync(async () => {
    dispatch(changeVisible(false));

    return getMultiLocationStats(
      listId,
      companyName,
      visitsDateValue[0].unix() * 1000,
      visitsDateValue[1].unix() * 1000,
    ).catch(() => ({
      totalVisitsMobile: 0,
      totalVisits: 1,
      totalCustomButtonClicks: 0,
      avgVisitsPerDay: 0,
      avgVisitsPerMonth: 0,
      totalDuration: 1,
      avgVisitDuration: 1,
    }));
  }, [listId, companyName, visitsDateValue]);

  const listLocations = useAsync(
    async () => getLocationsViaList(listId, companyName).catch(() => []),
    [listId, companyName],
  );

  const pointDurationStats = useAsync(async () => {
    if (listLocations.value) {
      return Promise.all(
        listLocations.value.map(location =>
          getStats(location.id, eventTypes.POINT_DURATION).then(stats =>
            stats.map(stat => ({
              ...stat,
              location: {
                id: location.id,
                name: location.name,
                previewImage: location.description.PREVIEW_IMAGE,
              },
            })),
          ),
        ),
      );
    }
    return Promise.resolve();
  }, [listLocations.value]);

  const multitourViewDurationStats = useAsync(async () => {
    if (listId)
      return getMultitourViewDurationStats(listId, companyName).catch(() => []);
    return null;
  }, [listId]);

  const multitourPointsViewDurationStats = useAsync(async () => {
    if (listId)
      return getMultitourPointViewDurationStats(listId, companyName).catch(
        () => [],
      );
    return null;
  }, [listId]);

  const multitourVisitsStats = useAsync(async () => {
    if (listId)
      return getMultitourVisitsStats(listId, companyName).catch(() => []);
    return null;
  }, [listId]);

  const pointClicksStats = useAsync(async () => {
    if (listId)
      return getMultitourPointClicksStats(
        listId,
        companyName,
        visitsDateValue[0].unix() * 1000,
        visitsDateValue[1].unix() * 1000,
      ).catch(() => []);
    return null;
  }, [listId, visitsDateValue]);

  const multitourMenuClickStats = useAsync(async () => {
    if (listId && visitsDateValue.length === 2) {
      const stats = await getMultitourMenuClickStats(
        listId,
        companyName,
        visitsDateValue[0].unix() * 1000,
        visitsDateValue[1].unix() * 1000
      )
        .catch(() => []);
      const sum = stats.reduce((acc, curr) => acc + curr.count, 0);

      return stats.filter(s => s.count / sum > 9 / 360);
    }

    return Promise.resolve({value: [], loading: true});
  }, [listId, companyName, visitsDateValue]);

  const mainLocation = useMemo(() => {
    if (listLocations.value) {
      return (
        listLocations.value.find(v => v.description.IS_MAIN) ||
        listLocations.value[0]
      );
    }
  }, [listLocations]);

  const topLocationPoints = useMemo(() => {
    return (pointDurationStats.value || []).map(xs =>
      findMax(xs, getPointDuration),
    );
  }, [pointDurationStats.value]);

  const multiTopPoint = useMemo(() => {
    return findMax(topLocationPoints, getPointDuration);
  }, [topLocationPoints]);

  const filteredPointClicksStats = useMemo(() => {
    if (pointClicksStats.value == null) return [];

    return [...pointClicksStats.value]
      .sort((a, b) => b.eventsCount - a.eventsCount)
      .slice(0, 30);
  }, [pointClicksStats.value]);

  const mobileVisitsPercent = useMemo(() => {
    if (summaryStats.value == null) return 0;

    return (
      (100 * summaryStats.value.totalVisitsMobile) /
      (summaryStats.value.totalVisitsMobile + summaryStats.value.totalVisits)
    );
  }, [summaryStats.value]);

  const desktopVisitsPercent = useMemo(() => {
    return 100 - mobileVisitsPercent;
  }, [mobileVisitsPercent]);

  const handleSaveViewDurationInPdf = useCallback((canvas, entity) => {
    return new Promise(rs => {
      const imgDataFirst = canvas.toDataURL('image/png', 1);
      const firstW = canvas.width;
      const firstH = canvas.height;

      setCurrentViewDurationEntity(prev =>
        prev === 'locationPoints' ? 'location' : 'locationPoints',
      );

      setTimeout(() => {
        const imgDataSecond = canvas.toDataURL('image/png', 1);

        const maxHeight = 430;
        const maxWidth = 750;

        const first = calculateImgSize(
          imgDataFirst,
          firstW,
          firstH,
          maxWidth,
          maxHeight,
        );
        const second = calculateImgSize(
          imgDataSecond,
          canvas.width,
          canvas.height,
          maxWidth,
          maxHeight,
        );

        const docDefinition = {
          content: [
            {
              text:
                entity === 'location'
                  ? 'Tour View Duration'
                  : 'Point View Duration',
              style: 'header',
            },
            {
              text:
                entity === 'location'
                  ? '\nThe amount of time users spent on a tour divided by day for the specified period\n'
                  : '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            first,
            {
              text:
                entity === 'location'
                  ? 'Point View Duration'
                  : 'Tour View Duration',
              style: 'header',
              margin: [0, maxHeight - first.height, 0, 0],
            },
            {
              text:
                entity === 'location'
                  ? '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n'
                  : '\nThe amount of time users spent on a tour divided by day for the specified period\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            second,
          ],
          styles: {
            header: {
              fontSize: 23,
              bold: true,
            },
          },
          pageOrientation: 'landscape',
        };
        pdfMake.createPdf(docDefinition).download();

        setCurrentViewDurationEntity(prev =>
          prev === 'locationPoints' ? 'location' : 'locationPoints',
        );

        setIsTitles({});
        rs();
      }, 0);
    });
  }, []);

  const handleSavePdf = useCallback(canvas => {
    const imgData = canvas.toDataURL('image/png', 1);
    const docDefinition = {
      content: [
        {
          text: 'Button Clicks',
          style: 'header',
        },
        {
          text: "Information about the user's actions with the viewed tour",
          fontSize: 10,
          margin: [0, 0, 0, 10],
        },
        calculateImgSize(imgData, canvas.width, canvas.height),
      ],
      styles: {
        header: {
          fontSize: 23,
          bold: true,
        },
      },
      pageOrientation: 'landscape',
    };

    pdfMake.createPdf(docDefinition).download();
  }, []);

  const handleFavoriteClick = useCallback(async () => {
    try {
      if (!favorites[listId]) {
        dispatch(auth.addFavorite(listId));
        await saveFavorite({ type: FavoriteType.TOUR, listId });
      } else {
        dispatch(auth.removeFavorite(listId));
        await removeFavoriteTour(listId);
      }
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, favorites, listId]);

  const changeVisitsTimestampHandler = useCallback(() => {
    refVisitsScroll.current.scrollLeft = 0;
  }, []);

  const changeDurationTimestampHandler = useCallback(() => {
    refDurationScroll.current.scrollLeft = 0;
  }, []);

  useEffect(() => {
    if (!isTitles.chart || isTitles.chart === charts.VIEW_DURATION) return;
    const { canvas, chart, text } = isTitles;

    setTimeout(() => {
      const imgData = canvas.toDataURL('image/png', 1);

      const docDefinition = {
        content: [
          {
            text: chart,
            style: 'header',
          },
          {
            text,
            fontSize: 10,
            margin: [0, 0, 0, 10],
          },
          calculateImgSize(imgData, canvas.width, canvas.height),
        ],
        styles: {
          header: {
            fontSize: 35,
            bold: true,
          },
        },
        pageOrientation: 'landscape',
      };

      pdfMake.createPdf(docDefinition).download();
      setIsTitles({});
    }, 0);
  }, [isTitles]);

  useEffect(() => {
    async function downloadPdf() {
      if (isTitles.chart !== charts.VIEW_DURATION || !isPdfDownloaded) return;
      const { canvas } = isTitles;
      setIsPdfDownloaded(false);
      await handleSaveViewDurationInPdf(canvas, currentViewDurationEntity);
      setIsPdfDownloaded(true);
    }

    downloadPdf();
  }, [
    currentViewDurationEntity,
    handleSaveViewDurationInPdf,
    isPdfDownloaded,
    isTitles,
  ]);

  useEffect(() => {
    if (!multitourVisitsStats.loading && multitourVisitsStats.value && multitourVisitsStats.value[0]) {
      dispatch(setInitialRange(defaultTimeRange(moment(multitourVisitsStats.value[0].eventDate))))
      dispatch(trySetRange(defaultTimeRange(moment(multitourVisitsStats.value[0].eventDate))))
    }
  }, [dispatch, multitourVisitsStats]);

  useEffect(() => {
    if (!multitourVisitsStats.loading && multitourVisitsStats.value && visitsDateValue) {
      setFilteredVisitStats(
        filterStatsByDate(multitourVisitsStats.value, visitsDateValue).slice(),
      );
    }
  }, [multitourVisitsStats, visitsDateValue]);

  useEffect(() => {
    if (
      !multitourPointsViewDurationStats.loading &&
      multitourPointsViewDurationStats.value &&
      visitsDateValue
    ) {
      setViewDurationStats({
        location: filterStatsByDate(
          multitourViewDurationStats.value,
          visitsDateValue,
        )
          .map(item => {
            return {
              label: moment(item.eventDate).format('DD.MM'),
              data: item,
            };
          })
          .slice(),

        locationPoints: [...multitourPointsViewDurationStats.value]
          .sort((a, b) => b.duration - a.duration)
          .map(item => {
            return {
              label: `#${item.pointId + 1}`,
              data: item,
            };
          })
          .slice(0, 30)
          .reverse(),
        value: [],
      });
    }
  }, [visitsDateValue, multitourPointsViewDurationStats.value, multitourViewDurationStats.value, multitourPointsViewDurationStats.loading]);

  useEffect(() => {
    if (viewDurationStats && visitsDateValue) {
      setFilteredViewDurationStats(viewDurationStats);
    }
  }, [viewDurationStats, currentViewDurationEntity, visitsDateValue]);

  useEffect(() => {
    if (viewDurationStats && visitsDateValue) {
      const dataViewDuration = calculateEventsInIntervals({
        data: viewDurationStats[GRAPH_TYPE.LOCATION].slice().map(({ data }) => {
          return {
            eventDate: data.eventDate,
            value: data.duration,
          };
        }),
        range: currentStep,
        start: visitsDateValue[0].format('YYYY-MM-DD'),
        end: visitsDateValue[1].format('YYYY-MM-DD'),
      });

      setFilteredViewDurationStats({
        [GRAPH_TYPE.LOCATION]: dataViewDuration
          .reverse()
          .map(({ eventDate, value }) => ({
            label: moment(eventDate).format('DD.MM'),
            data: {
              eventDate,
              duration: value,
            },
          })),
        [GRAPH_TYPE.POINTS]: viewDurationStats[GRAPH_TYPE.POINTS],
      });
    }
  }, [currentStep, currentViewDurationEntity, viewDurationStats, visitsDateValue]);

  useEffect(() => {
    if (multitourVisitsStats.value != null && visitsDateValue) {
      const dataVisits = calculateEventsInIntervals({
        data: multitourVisitsStats.value.slice().map(({ eventDate, eventsCount }) => {
          return {
            eventDate,
            value: eventsCount,
          };
        }),
        range: currentStep,
        start: visitsDateValue[0].format('YYYY-MM-DD'),
        end: visitsDateValue[1].format('YYYY-MM-DD'),
      });

      setFilteredVisitStats(
        dataVisits.reverse().map(({ eventDate, value }) => ({
          eventDate,
          eventsCount: value,
        })),
      );
    }
  }, [currentStep, multitourVisitsStats, visitsDateValue]);

  useEffect(() => {
    saveRecentlyViewed({ type: RecentlyViewedType.TOUR, listId })
      .then(res => dispatch(recentlyViewed.push(res)))
      .catch(console.error);
  }, [dispatch, listId]);

  useEffect(() => {
    const getLocationDetails = async () => {
      if (!multiTopPoint || !multiTopPoint.location) {
        return;
      }

      multiTopPoint.location = await getLocation(multiTopPoint.location.id);
    };

    getLocationDetails();
  }, [multiTopPoint]);

  if (
    !multitourViewDurationStats ||
    !multitourViewDurationStats.value ||
    !multitourPointsViewDurationStats.value ||
    !multitourVisitsStats.value ||
    !pointClicksStats.value ||
    !listLocations.value
  ) {
    return <React.Fragment />;
  }

  if (!summaryStats.value || !pointDurationStats.value) {
    return <React.Fragment />;
  }

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <div
          style={{ display: 'flex', alignItems: 'center', color: '#6C6C6C' }}
        >
          <div
            role="button"
            onClick={() => navigate(-1)}
            className={classes.backIcon}
          />
          <div>
            <div style={{ fontSize: '1.5rem' }}>
              <span style={{ color: '#333' }}>{listId}</span>
            </div>
            <div style={{ fontWeight: 400, fontSize: '0.875rem' }}>
              Summarized statistics data for the all locations inside the Virtual Tour
            </div>
          </div>
        </div>
      </Header>
      <div className="w-full flex">
        <div
          className="w-7/12 flex gap-4"
          style={{ padding: '15px 0px 10px 0' }}
        >
          <p
            style={{
              fontSize: '12px',
              whiteSpace: 'nowrap',
              paddingTop: '5.5px',
            }}
          >
            Embed code
          </p>
          <CopyInput
            value={
              mainLocation &&
              `<iframe allowfullscreen title="view_player" src="${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${mainLocation.id}" width="100%" height="500" />`
            }
          />
          <div>
            <a
              style={{
                paddingInline: '4.5px',
                paddingBlock: '3.5px 4px',
                borderRadius: '5px',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 4px',
                outline: 'none',
                border: '0px',
                textOverflow: 'ellipsis',
                fontSize: '0.7rem',
                whiteSpace: 'nowrap',
                background: 'white',
              }}
              target="_blank"
              href={`${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${mainLocation.id}`}
              rel="noreferrer"
            >
              Launch the tour
            </a>
          </div>
          <div style={{ paddingBlock: '.3rem' }}>
            <MultiDateRangePicker />
          </div>
        </div>
      </div>
      <div
        className="w-full flex flex-wrap"
        style={{
          width: 'calc(100% + 8px)',
          marginLeft: '-4px',
        }}
      >
        <InfoCard
          className="px-1"
          title="Total Visits"
          value={
            (summaryStats.value.totalVisits || 0) +
            (summaryStats.value.totalVisitsMobile || 0)
          }
        />
        <InfoCard
          className="px-1"
          title="Avg. Visits Per Day"
          fullTitle="Average Visits Per Day"
          value={renderDecimal(summaryStats.value.avgVisitsPerDay || 0)}
        />
        <InfoCard
          className="px-1"
          title="Avg. Visits Per Month"
          fullTitle="Average Visits Per Month"
          value={renderDecimal(Number(summaryStats.value.avgVisitsPerMonth || 0))}
        />
        <InfoCard
          className="px-1"
          title="Total Duration"
          value={
            formatDateFromSeconds(summaryStats.value.totalDuration) || '00:00'
          }
          tooltipText='Total Duration of viewing of the Virtual Tour'
        />
        <InfoCard
          className="px-1"
          title="Avg. Visit Duration"
          fullTitle="Average Visit Duration"
          value={
            formatDateFromSeconds(summaryStats.value.avgVisitDuration || 0) ||
            '00:00'
          }
        />
        <InfoCard
          className="px-1"
          title="Conversion"
          value={summaryStats.value.totalCustomButtonClicks}
          tooltipText='Amount of clicks to the Envelope Button at the main bar panel'
        />
        <InfoCard
          className="px-1"
          title="Custom Button Clicks"
          value={summaryStats.value.totalHotspotButtonClicks}
          tooltipText='Amounts of clicks on the all buttons inside informational hotspots'
        />
      </div>

      <div
        className="w-full flex mt-1 mb-2"
        style={{ borderRadius: '10px', minHeight: '273px' }}
      >
        <div
          className="w-1/2 h-auto mr-1 bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            // overflow: 'hidden',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex justify-between flex-wrap"
            style={{ paddingTop: 18 }}
          >
            <div
              className="flex"
              style={{
                marginBottom: 18,
                fontSize: '0.75rem',
                alignItems: 'center',
              }}
            >
              <Tooltip placement='top' title='Amount of visits for applied range of times'>
                <span>
                  Visits
                </span>
              </Tooltip>
              <DownloadButton
                className={classes.upload}
                onClick={() =>
                  setIsTitles({
                    canvas: refVisits.current.chartInstance.canvas,
                    chart: charts.VISITS,
                    text: '\nTotal number of visits for the specified period',
                  })
                }
              />
            </div>
            <div
              className="flex"
              style={{ marginBottom: '18px', fontSize: '12px' }}
            >
              <TimeStep
                // setCurrentStep={setCurrentStepVisits}
                visitsDateValue={visitsDateValue}
                onClick={changeVisitsTimestampHandler}
              />
            </div>
          </div>

          <div
            className={`w-full relative flex flex-col ${classes.h12}`}
            ref={refVisitsScroll}
            style={{
              overflow: 'auto',
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                minWidth:
                  filteredVisitStats.length *
                  calculateChartSize(currentStep),
              }}
            >
              {!isChartLoading.visitStats ? (
                <Line
                  ref={refVisits}
                  data={{
                    datasets: [
                      {
                        backgroundColor: gradient,
                        lineTension: 0.01,
                        borderColor: '#36A6FD',
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent',
                        pointRadius: size(15),
                        pointHoverRadius: size(15),
                        borderWidth: 1,
                        parsing: false,
                        data: filteredVisitStats.map(item => item.eventsCount),
                        label: '',
                      },
                    ],
                    labels: formatLabels(filteredVisitStats, currentStep),
                  }}
                  options={{
                    normalized: true,
                    animation: false,
                    cornerRadius: size(20),
                    layout: {
                      padding: {
                        right: size(13),
                      },
                    },
                    legend: { display: false },
                    maintainAspectRatio: false,
                    responsive: true,
                    devicePixelRatio: window.devicePixelRatio * 2,

                    hover: {
                      onHover(e, [chart]) {
                        if (!chart) {
                          dispatch(
                            updateVisitsTooltipInfo({
                              show: false,
                              showDashes: false,
                              showLabel: false,
                              showCircle: false,
                            }),
                          );
                          return;
                        }

                        const value = filteredVisitStats[chart._index];

                        dispatch(
                          setVisitsTooltipInfo({
                            showCircle: true,
                            showDashes: true,
                            type: TOOLTIP_TYPE.SMALL,
                            show: true,
                            value,
                            label: chart._xScale.ticks[chart._index],
                            smallTooltipText: `${value.eventsCount}`,
                            dashesHeight: `${
                              refVisits.current.chartInstance.height - 20
                            }px`,
                            showLabel: true,
                            tooltipLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            tooltipTop: chart._view.y + 28,
                            circleLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              10,
                            circleTop: chart._view.y + 50,
                            dashesLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            dashesTop:
                              e.target.parentElement.parentElement.offsetTop,
                            labelLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            labelBottom: 15,
                          }),
                        );
                      },
                    },
                    tooltips: {
                      enabled: false,
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: size(8),
                            reverse: true,
                          },
                          categoryPercentage: 0.97,
                          barPercentage: 0.97,
                          barThickness: 28,
                          scaleLabel: {
                            display: isTitles.chart === charts.VISITS,
                            labelString: 'Date',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontStyle: 'bold',
                            fontColor: '#000',
                            stepSize: 250,
                            beginAtZero: true,
                          },
                          scaleLabel: {
                            display: isTitles.chart === charts.VISITS,
                            labelString: 'Visits',
                          },
                        },
                      ],
                    },
                  }}
                />
              ) : (
                <Skeleton height="99%" />
              )}
            </div>
          </div>
          <GraphTooltip store="visitsTooltip" />
        </div>

        <div
          className="w-1/2 h-auto mr-1 bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex justify-between flex-wrap"
            style={{ paddingTop: 18 }}
          >
            <div
              className="flex"
              style={{
                marginBottom: 18,
                fontSize: '0.75rem',
                alignItems: 'center',
              }}
            >
              <Tooltip placement='top' title='Statistics for all interface buttons clicks'>
                <span>Button Clicks</span>
              </Tooltip>
              <DownloadButton
                className={classes.upload}
                onClick={() =>
                    handleSavePdf(refDoughnut.current.chartInstance.canvas)
                  }
              />
            </div>
          </div>

          <div
            className={`w-full relative flex flex-col ${classes.h12}`}
            style={{
              overflow: 'auto',
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
              }}
            >
              <Doughnut
                ref={refDoughnut}
                data={{
                  datasets: [
                    {
                      backgroundColor: (multitourMenuClickStats.value || []).map(
                        x => x.color,
                      ),
                      data: (multitourMenuClickStats.value || []).map(x => x.count),
                      datalabels: {
                        labels: {
                          value: {},
                          name: {
                            align: 'end',
                            anchor: 'end',
                            offset: -1,
                            font: { size: 1 },
                            padding: 0,
                            backgroundColor: '#A0A0A0',
                            color: '#A0A0A0',
                            formatter() {
                              return '................................................................................................';
                            },
                            rotation(value) {
                              const sum = value.dataset.data.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                              );
                              const position =
                                value.dataset.data
                                  .slice(0, value.dataIndex + 1)
                                  .reduce(
                                    (accumulator, currentValue) =>
                                      accumulator + currentValue,
                                  ) -
                                value.dataset.data[value.dataIndex] / 2;
                              return (position / sum) * 360 - 90;
                            },
                          },
                        },
                      },
                    },
                  ],
                  labels: (multitourMenuClickStats.value || []).map(x => x.name),
                }}
                options={{
                  maintainAspectRatio: false,
                  devicePixelRatio: window.devicePixelRatio * 2,
                  legend: {
                    display: true,
                    position: document.body.offsetWidth > 1500 ? 'left' : 'top',
                    labels: {
                      fontSize: size(12),
                    },
                  },
                  layout: {
                    padding: 50,
                  },
                  scales: {
                    xAxes: [{ display: false }],
                    yAxes: [{ display: false }],
                  },
                  pieceLabel: {
                    render: 'label',
                    fontColor: '#000',
                    position: 'outside',
                  },
                  plugins: {
                    datalabels: {
                      anchor: 'end',
                      align: 'end',
                      offset: 22,
                      color: '#A0A0A0',
                      font: {
                        size: size(12),
                      },
                    },
                  },
                }}
                plugins={[
                  ChartDataLabels,
                  {
                    beforeInit(chart, options) {
                      chart.legend.afterFit = function () {
                        this.height += 50;
                        this.legendHitBoxes.forEach(element => {
                          element.left = 0;
                        });
                      };
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full flex mb-3"
        style={{ borderRadius: '10px', minHeight: '273px' }}
      >
        <div
          className="w-1/2 h-auto mr-1 bg-white relative flex flex-col"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            style={{
              visibility: isPdfDownloaded ? 'visible' : 'hidden',
            }}
          >
            <div
              className="flex justify-between flex-wrap w-full"
              style={{ marginTop: 18 }}
            >
              <div style={{ marginBottom: 18 }} className="flex">
                <Tooltip placement='top' title='The amount of time the tour was viewed per day'>
                  <span
                    role="button"
                    style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                    className={cn({
                    [classes.viewDurationSetter]: true,
                    active: currentViewDurationEntity === GRAPH_TYPE.LOCATION,
                  })}
                    onClick={e => {
                    e.stopPropagation();
                    setCurrentViewDurationEntity(GRAPH_TYPE.LOCATION);
                  }}
                  >
                    Tour View Duration
                  </span>
                </Tooltip>
                <Tooltip placement='top' title='The amount of time each 360 point was viewed'>
                  <span
                    role="button"
                    style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                    className={cn({
                    [classes.viewDurationSetter]: true,
                    active: currentViewDurationEntity === GRAPH_TYPE.POINTS,
                  })}
                    onClick={e => {
                    e.stopPropagation();
                    setCurrentViewDurationEntity(GRAPH_TYPE.POINTS);
                  }}
                  >
                    Point View Duration
                  </span>
                </Tooltip>
                <DownloadButton
                  className={classes.upload}
                  onClick={() =>
                    setIsTitles({
                      canvas: refDuration.current.chartInstance.canvas,
                      chart: charts.VIEW_DURATION,
                    })
                  }
                />
              </div>
              {currentViewDurationEntity === 'location' && (
                <div
                  className="flex"
                  style={{ marginBottom: '18px', fontSize: '12px' }}
                >
                  <TimeStep
                    // setCurrentStep={setCurrentStepViewDuration}
                    visitsDateValue={visitsDateValue}
                    onClick={changeDurationTimestampHandler}
                  />
                </div>
              )}
            </div>

            <div
              className={`relative ${classes.h14}`}
              ref={refDurationScroll}
              style={{
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '100%',
                  minWidth:
                    currentViewDurationEntity === 'location'
                      ? filteredViewDurationStats[currentViewDurationEntity]
                          .length * calculateChartSize(currentStep)
                      : filteredViewDurationStats[currentViewDurationEntity]
                          .length * 32,
                }}
              >
                {!isChartLoading.viewDurationStats ? (
                  <Bar
                    ref={refDuration}
                    data={{
                      labels: filteredViewDurationStats[
                        currentViewDurationEntity
                      ].map(x => x.label),
                      datasets: [
                        {
                          backgroundColor: viewDurationGradient,
                          hoverBackgroundColor: viewDurationGradientHover,
                          data: filteredViewDurationStats[
                            currentViewDurationEntity
                          ].map(x => Math.round(x.data.duration / 60)),
                          label: '',
                          datasetLabel: '',
                        },
                      ],
                    }}
                    options={{
                      animation: false,
                      cornerRadius: 20,
                      layout: { padding: 0 },
                      legend: { display: false },
                      maintainAspectRatio: false,
                      responsive: true,
                      devicePixelRatio: window.devicePixelRatio * 2,

                      onHover(e, [chart]) {
                        if (!chart) {
                          if (currentViewDurationEntity === GRAPH_TYPE.POINTS) {
                            if (timeout.current.show) {
                              clearTimeout(timeout.current);

                              timeout.current.timeout = setTimeout(() => {
                                dispatch(hideDurationTooltip());
                                timeout.current.show = false;
                              }, hideTimeout);
                            }
                          } else {
                            dispatch(hideDurationTooltip());
                          }

                          return;
                        }

                        const value =
                          filteredViewDurationStats[currentViewDurationEntity][
                            chart._index
                          ].data;
                        const { locationId, pointId } = value;
                        const locationName = (
                          listLocations.value.find(
                            item => item.id === locationId,
                          ) || { name: '' }
                        ).name;

                        dispatch(
                          setDurationTooltipInfo({
                            locationId,
                            locationName,
                            pointId,
                            show: true,
                            bigTooltipText: `Duration: ${moment
                              .utc(value.duration * 1000)
                              .format('HH:mm:ss')}`,
                            type:
                              currentViewDurationEntity === GRAPH_TYPE.LOCATION
                                ? TOOLTIP_TYPE.SMALL
                                : TOOLTIP_TYPE.BIG,
                            value,
                            smallTooltipText: `${Math.round(
                              value.duration / 60,
                            )}m`,
                            tooltipTop:
                              currentViewDurationEntity === GRAPH_TYPE.LOCATION
                                ? chart._view.y +
                                  e.target.parentElement.parentElement
                                    .offsetTop -
                                  30
                                : chart._view.y + 63,
                            tooltipLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                          }),
                        );
                        timeout.current.show = true;
                      },
                      tooltips: {
                        enabled: false,
                        intersect: false,
                      },
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              fontSize: size(8),
                              reverse: true,
                            },
                            categoryPercentage: 0.97,
                            barPercentage: 0.97,
                            barThickness:
                              currentViewDurationEntity === 'location'
                                ? calculateChartSize(currentStep) -
                                  2
                                : 30,
                            scaleLabel: {
                              display: isTitles.chart === charts.VIEW_DURATION,
                              labelString:
                                currentViewDurationEntity === 'location'
                                  ? 'Date'
                                  : 'Point',
                            },
                          },
                        ],
                        yAxes: [
                          {
                            // afterFit(scaleInstance) {
                            //   setViewDurationChartScaleWidth(scaleInstance.width);
                            // },
                            ticks: {
                              fontStyle: 'bold',
                              fontColor: '#000',
                              stepSize: 250,
                              beginAtZero: true,
                            },
                            scaleLabel: {
                              display: isTitles.chart === charts.VIEW_DURATION,
                              labelString: 'Time (m)',
                            },
                          },
                        ],
                      },
                    }}
                  />
                ) : (
                  <Skeleton height="99%" />
                )}
              </div>
            </div>
            <GraphTooltip
              store="durationTooltip"
              updateFunc={updateDurationTooltipInfo}
              hideFunc={hideDurationTooltip}
            />
          </div>
          {!isPdfDownloaded && (
            <Skeleton
              height="100%"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: '10px',
              }}
            />
          )}
        </div>

        <div
          className="w-1/2 h-auto ml-1 bg-white relative flex flex-col"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex items-center"
            style={{ padding: '19px 0', fontSize: '0.75rem' }}
          >
            <div>
              <Tooltip placement='top' title="The amount of visits of each individual 360 point">
                <span>
                  Point Visits
                </span>
              </Tooltip>
            </div>
            <DownloadButton
              className={classes.upload}
              onClick={() =>
                  setIsTitles({
                    canvas: refPointVisits.current.chartInstance.canvas,
                    chart: charts.POINTS_VISITS,
                    text: '\nThe number of visits to the tour points divided by the number of point views',
                  })
                }
            />
          </div>
          <div
            className={`relative ${classes.h14}`}
            style={{ overflow: 'auto' }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                minWidth: filteredPointClicksStats.length * size(32),
              }}
            >
              <Bar
                ref={refPointVisits}
                data={{
                  labels: filteredPointClicksStats.map(
                    item => `#${item.pointId + 1}`,
                  ),
                  datasets: [
                    {
                      backgroundColor: viewDurationGradient,
                      hoverBackgroundColor: viewDurationGradientHover,
                      data: filteredPointClicksStats.map(
                        item => item.eventsCount,
                      ),
                      label: '',
                      datasetLabel: '',
                    },
                  ],
                }}
                options={{
                  animation: false,
                  cornerRadius: 20,
                  layout: { padding: 0 },
                  legend: { display: false },
                  maintainAspectRatio: false,
                  responsive: true,
                  devicePixelRatio: window.devicePixelRatio * 2,

                  onHover(e, [chart]) {
                    if (!chart) {
                      if (timeout.current.show) {
                        clearTimeout(timeout.current);

                        timeout.current.timeout = setTimeout(() => {
                          dispatch(hidePointVisitsTooltip());
                          timeout.current.show = false;
                        }, hideTimeout);
                      }

                      return;
                    }

                    const value = filteredPointClicksStats[chart._index];
                    const { locationId, pointId } = value;
                    const locationName = (
                      listLocations.value.find(
                        item => item.id === locationId,
                      ) || { name: '' }
                    ).name;

                    const { data } = filteredViewDurationStats[
                      GRAPH_TYPE.POINTS
                    ].find(
                      item =>
                        item.data.locationId === locationId &&
                        item.data.pointId === pointId,
                    );

                    dispatch(
                      setPointsVisitTooltipInfo({
                        locationId,
                        locationName,
                        pointId,
                        show: true,
                        scrollX:
                          e.target.parentElement.parentElement.scrollLeft,
                        offsetY: e.target.parentElement.parentElement.offsetTop,
                        caretX: chart._view.x,
                        caretY: chart._view.y,
                        center: true,
                        label: chart._xScale.ticks[chart._index],
                        type: TOOLTIP_TYPE.BIG,
                        bigTooltipText: (
                          // eslint-disable-next-line react/jsx-indent
                          <div style={{ fontSize: '0.6rem' }}>
                            <div>Clicks: {value.eventsCount}</div>
                            <div>
                              Duration:{' '}
                              {moment.utc(data.duration * 1000).format('HH:mm')}
                            </div>
                          </div>
                        ),
                        tooltipTop: chart._view.y + 65,
                        tooltipLeft:
                          chart._view.x -
                          e.target.parentElement.parentElement.scrollLeft +
                          20,
                      }),
                    );
                    timeout.current.show = true;
                  },
                  tooltips: {
                    enabled: false,
                    intersect: false,
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontSize: 8,
                        },
                        categoryPercentage: 0.97,
                        barPercentage: 0.97,
                        barThickness: 28,
                        scaleLabel: {
                          display: isTitles.chart === charts.POINTS_VISITS,
                          labelString: 'Point',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        // afterFit(scaleInstance) {
                        //   setClicksChartScaleWidth(scaleInstance.width);
                        // },
                        ticks: {
                          fontStyle: 'bold',
                          fontColor: '#000',
                          stepSize: 300,
                          beginAtZero: true,
                        },
                        scaleLabel: {
                          display: isTitles.chart === charts.POINTS_VISITS,
                          labelString: 'Clicks',
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>
          <GraphTooltip
            store="pointsVisitsTooltip"
            hideFunc={hidePointVisitsTooltip}
            updateFunc={updatePointsVisitTooltipInfo}
          />
        </div>
      </div>

      <div className="w-full flex">
        {multiTopPoint && multiTopPoint.location.description && (
          <TopPoint
            locationId={multiTopPoint.location.id}
            parentId={multiTopPoint.location.description.PARENT_VARIANT}
            topPointId={multiTopPoint.pointId}
          />
        )}
        <div className={multiTopPoint ? 'pl-4 w-2/3' : 'w-full'}>
          <ViewsByDevices
            mobilePercent={mobileVisitsPercent}
            desktopPercent={desktopVisitsPercent}
            mobileCount={summaryStats.value.totalVisitsMobile}
            desktopCount={summaryStats.value.totalVisits}
          />
        </div>
      </div>

      <div className="w-full flex mt-3">
        <Button
          color="primary"
          variant="contained"
          fullWidth
          size="large"
          type="button"
          onClick={handleFavoriteClick}
        >
          {favorites[listId] ? 'Remove from favorites' : 'Add to favorite'}
        </Button>
      </div>
    </Page>
  );
};

export default MultilocationOverviewView;
