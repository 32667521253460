/* eslint-disable prettier/prettier */
import React, {useState, useEffect} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

import { existsOnServer } from '~/services/api';
import getPointPreviewURL, { getPointCubePreviewUrl, getStagingPointPreviewURL } from '~/utils/getPointPreviewURL';
import { TOOLTIP_TYPE } from '../constants';

const useStyles = makeStyles(theme => ({
    triangle: {
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '5px 0 5px 5px',
      borderColor: 'transparent transparent transparent #000000',
  
      [theme.breakpoints.up('hiRes')]: {
        borderWidth: '0.3rem 0 0.3rem 0.3rem',
      },
    },
    smallTooltip: {
      borderRadius: '3px',
      transform: 'translate(-50%, -50%)',
      width: 'fit-content',
      minWidth: '50px',
      height: '30px',
      background: '#36A6FD',
  
      [theme.breakpoints.up('hiRes')]: {
        borderRadius: '0.188rem',
        width: '3.125rem',
        height: '1.875rem',
      },
    },
    bigTooltip: {
      transform: 'translate(-50%, -100%)',
      borderRadius: '3px',
      width: '187px',
      paddingBottom: '20px',
      transition: 'opacity, left 0.1s ease',
      display: 'block',
      zIndex: 10,
  
      [theme.breakpoints.up('hiRes')]: {
        borderRadius: '0.188rem',
        width: '11.69rem',
        height: '9.06rem',
        paddingBottom: '1.25rem',
      },
    },
    tooltipImg: {
      height: '70px',
      width: '100%',
      objectFit: 'cover',
      position: 'relative',
  
      [theme.breakpoints.up('hiRes')]: {
        height: '4.375rem',
      },
    },
    smallTriangle: {
      position: 'absolute',
      top: '20px',
      borderLeft: '18px solid transparent',
      borderRight: '18px solid transparent',
      borderTop: '16px solid #36A6FD',
  
      [theme.breakpoints.up('hiRes')]: {
        top: '1.25rem',
        borderLeft: '1.125rem solid transparent',
        borderRight: '1.125rem solid transparent',
        borderTop: '1rem solid #36A6FD',
      },
    },
    verySmallTriangle: {
      position: 'absolute',
      top: '-6px',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #363636',
      left: '50%',
      transform: 'translateX(-50%)',
      pointerEvents: 'none',
  
      [theme.breakpoints.up('hiRes')]: {
        top: '-0.375rem',
        borderLeft: '0.625rem solid transparent',
        borderRight: '0.625rem solid transparent',
        borderBottom: '0.625rem solid #363636',
      },
    },
    bigTriangle: {
      position: 'absolute',
      bottom: '5px',
      /* top: '120px', */
      borderLeft: '18px solid transparent',
      borderRight: '18px solid transparent',
      borderTop: '16px solid #36A6FD',
      left: '50%',
      transform: 'translateX(-50%)',
      pointerEvents: 'none',
  
      [theme.breakpoints.up('hiRes')]: {
        top: '7.5rem',
        borderLeft: '1.125rem solid transparent',
        borderRight: '1.125rem solid transparent',
        borderTop: '1rem solid #36A6FD',
      },
    },
    bigTooltipPaper: {
      padding: '5px 5px 15px',
      pointerEvents: 'none',
      background: '#36A6FD',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
  
      [theme.breakpoints.up('hiRes')]: {
        padding: '0.3rem 0.3rem 1rem',
      },
    },
    activeNumber: {
      transform: 'translateX(-50%)',
      bottom: '4px',
      pointerEvents: 'none',
      height: '12px',
      color: '#36A6FD',
      fontSize: '0.625rem',
  
      [theme.breakpoints.up('hiRes')]: {
        buttom: '0.25rem',
        height: '0.75rem',
      },
    },
    bigCircle: {
      width: '20px',
      height: '20px',
      background: 'rgb(54, 166, 253, 0.2)',
      top: '40px',
  
      [theme.breakpoints.up('hiRes')]: {
        width: '1.25rem',
        height: '1.25rem',
        top: '2.5rem',
      },
    },
    mediumCircle: {
      width: '14px',
      height: '14px',
      background: 'rgb(54, 166, 253, 0.4)',
  
      [theme.breakpoints.up('hiRes')]: {
        width: '0.875rem',
        height: '0.875rem',
      },
    },
    smallCircle: {
      width: '7px',
      height: '7px',
      background: '#36A6FD',
  
      [theme.breakpoints.up('hiRes')]: {
        width: '0.5rem',
        height: '0.5rem',
      },
    },
  }));

export function GraphTooltip({store, updateFunc, hideFunc}) {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();

  const { 
    type, 
    show, 
    pointId, 
    locationId, 
    locationName, 
    bigTooltipText, 
    smallTooltipText,
    showDashes,
    dashesHeight,
    showLabel,
    label,
    showCircle,
    tooltipLeft,
    tooltipTop,
    circleLeft,
    circleTop,
    dashesLeft,
    dashesTop,
    labelLeft,
    labelBottom 
  } = useSelector(
    state => state[store],
  );

  const classes = useStyles()

  useEffect(() => {
    const fetchImage = async () => {
      setIsLoading(true);

      const [existsInLocation] = await Promise.all([
        existsOnServer(getPointPreviewURL(pointId + 1, locationId)),
      ]);

      let newSrc;

      if (existsInLocation) {
        newSrc = getPointPreviewURL(pointId + 1, locationId);
      } else {
        const existsInStagingLocation = await existsOnServer(
          getStagingPointPreviewURL(pointId + 1, locationId),
        );

        if (existsInStagingLocation) {
          newSrc = getStagingPointPreviewURL(pointId + 1, locationId);
        } else {
          const cubePreviewUrl = getPointCubePreviewUrl(
            pointId + 1,
            locationId,
          );
          const existsCubePreview = await existsOnServer(cubePreviewUrl);

          if (existsCubePreview) {
            newSrc = cubePreviewUrl;
          }
        }
      }

      setUploadedImage(newSrc);
      setIsLoading(false);
    };

    if(pointId !== null && type === TOOLTIP_TYPE.BIG){
        fetchImage();
    }
  }, [pointId, locationId, type]);

  return (
    <>
      {
        showCircle &&  
        <div
          style={{
            left: circleLeft,
            top: circleTop,
            zIndex: 5,
            pointerEvents: 'none',
          }}
          className={`absolute flex justify-center items-center rounded-full ${classes.bigCircle}`}
        >
          <div
            className={`flex justify-center items-center rounded-full ${classes.mediumCircle}`}
          >
            <div className={`rounded-full ${classes.smallCircle}`} />
          </div>
        </div>
      }
      { showDashes &&  
        <div
          style={{
              position: 'absolute',
              opacity: 1,
              left: dashesLeft,
              top: dashesTop || 0,
              width: '0px',
              borderLeft: '2px dashed rgba(255, 255, 255,1)',
              height: dashesHeight,
              background: '#36A6FD',
              pointerEvents: 'none',
            }}
        />
      }
      {
        showLabel &&  
        <span
          className={`absolute bg-white font-bold ${classes.activeNumber}`}
          style={{
          position: 'absolute',
          opacity: 1,
          left: labelLeft,
          bottom: labelBottom,
        }}
        >
          {label}
        </span>
      }
      {
        show && type === TOOLTIP_TYPE.SMALL && 
        <div
          className={`absolute inset-0 font-bold text-xs text-white flex justify-center items-center ${classes.smallTooltip}`}
          style={{
            position: 'absolute',
            opacity: show ? 1 : 0,
            left: tooltipLeft,
            top: tooltipTop,
            transition: 'opacity, left 0.4s ease',
        }}
        >
          <div className={classes.smallTriangle} />
          <span className="relative pl-1 pr-1">
            {smallTooltipText}
          </span>
        </div>
      }
      {
        show && type === TOOLTIP_TYPE.BIG && 
        <a
          href={`${process.env.PLAYER_URL}?locationId=${locationId}&point_id=${pointId + 1}`}
          target="_blank"
          onBlur={() => {}}
          rel="noopener noreferrer"
          onMouseEnter={() => {
            if(updateFunc){
              dispatch(updateFunc({ cursorInside: true }))
            }
          }}
          onMouseOut={() => {
            if(hideFunc){
              dispatch(hideFunc({
                force: true
              }))
            }
          }}
          className={`absolute font-bold text-xs text-white flex justify-center items-center ${classes.bigTooltip}`}
          style={{
              left: tooltipLeft,
              top: tooltipTop,
            }}
        >
          <div className={`w-full h-full ${classes.bigTooltipPaper}`}>
            <div className={classes.bigTriangle} />
            <div
              className="w-full flex justify-between font-bold pt-1"
              style={{ fontSize: '0.75rem' }}
            >
              <div style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              >
                Point #{pointId + 1}
                <div
                  style={{
                  marginBottom: '5px',
                  margintop: '-3px',
                  fontSize: '0.5625rem',
                  fontWeight: '500',
                }}
                >
                  {locationName}
                </div>
              </div>
              <div>
                {bigTooltipText}
              </div>
            </div>
           
            {isLoading && (
            <Skeleton
              height={70}
              style={{ position: 'relative', bottom: '4px' }}
            />
              )}
            {!isLoading && (
            <img
              alt=""
              className={classes.tooltipImg}
              style={{
                    display: uploadedImage ? 'inline' : 'none',
                  }}
              src={uploadedImage}
            />
              )}
          </div>
        </a>
    }      
    </>
  );
}
