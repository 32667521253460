import React from 'react';

import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import toPercent from '~/utils/toPercent';

const useStyles = makeStyles(theme => ({
  deviceItem: {
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      color: '#36A6FD',
    },

    '&>.icon': {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      width: '20%',
    },

    '& .mobile-icon': {
      backgroundImage: 'url(/static/icons/mobile-device.svg)',
    },
    '& .desktop-icon': {
      backgroundImage: 'url(/static/icons/desktop-device.svg)',
    },
    '& .tablet-icon': {
      backgroundImage: 'url(/static/icons/tablet-device.svg)',
    },

    '&:hover .mobile-icon': {
      backgroundImage: 'url(/static/icons/mobile-device-active.svg)',
    },
    '&:hover .desktop-icon': {
      backgroundImage: 'url(/static/icons/desktop-device-active.svg)',
    },
    '&:hover .tablet-icon': {
      backgroundImage: 'url(/static/icons/tablet-device-active.svg)',
    },
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    margin: 4,
  },
}));

export default function ViewsByDevices({
  mobilePercent,
  desktopPercent,
  mobileCount,
  desktopCount,
}) {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();

  return (
    <div
      className="h-full w-full bg-white"
      style={{
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <span className="font-bold text-xs">Views by devices</span>

      <div
        className="w-full flex flex-wrap flex-grow"
        style={{ marginTop: '15px' }}
      >
        <Tooltip
          classes={tooltipClass}
          title={`Total visits: ${mobileCount}`}
          placement="bottom"
        >
          <div
            className={clsx('w-1/2 flex justify-center', classes.deviceItem)}
          >
            <div
              className="icon mobile-icon"
              style={{ marginRight: '10px', width: '10%' }}
            />
            <div className="flex flex-col justify-center">
              <div className="text-2xl">
                {Math.round(toPercent(mobilePercent))}%
              </div>
              <div className="text-xs">Mobile</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip
          classes={tooltipClass}
          title={`Total visits: ${desktopCount}`}
          placement="bottom"
        >
          <div
            className={clsx('w-1/2 flex justify-center', classes.deviceItem)}
          >
            <div
              className="icon desktop-icon"
              style={{ marginRight: '10px' }}
            />
            <div className="flex flex-col justify-center">
              <div className="text-2xl">
                {Math.round(toPercent(desktopPercent))}%
              </div>
              <div className="text-xs">Desktop</div>
            </div>
          </div>
        </Tooltip>

        {/* <div className={clsx('w-1/3 flex', classes.deviceItem)}>
          <div className="icon tablet-icon" style={{ marginRight: '10px' }} />
          <div>
            <div className="text-2xl">15%</div>
            <div className="text-xs">tablets</div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
